import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ProjectCard title="🎧 Chill" bg="linear-gradient(to right, #461182 0%, #5f41a2 100%)" mdxType="ProjectCard">
  Lose yourself in the music while taking in the incredible visuals
    </ProjectCard>
    <ProjectCard title="🚀 Go Fast" bg="linear-gradient(to right, #5a0058 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Use <i>Turbo</i> to help you get to the next checkpoint or get through a tight space before it closes up
    </ProjectCard>
    <ProjectCard title="⌚ Go Slow" bg="linear-gradient(to right, #01632c 0%, #48bb78 100%)" mdxType="ProjectCard">
  Use <i>Slow-Mo</i> to slow down time to get you out of sticky situations or squeeze through a tight spot
    </ProjectCard>
    <ProjectCard title="🏆 Compete" bg="linear-gradient(to right, #a52d00 0%, #de6f06 100%)" mdxType="ProjectCard">
  Think you're the best? <i>Prove it</i> with the global leaderboard
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      